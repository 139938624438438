import React, { useState } from "react";
import { PageProps } from "gatsby";
import { LayoutProvider, Wrapper } from '../../components/layout';
import { stackStyled } from '../../theme';
import { StackOnBreadcrumbs } from '../../atoms';
import { useViewPortSize } from '../../hooks';
import { ResponsiveContainer } from '../../atoms/Containers';
import { PaypalPayment } from '../../components/sections';
import CombinationPaypal  from '../../components/sections/PaypalPayment/CombinationPaypal';

const BreadCrumbsContainer = stackStyled(
  "div",
  (props: { isMobile: boolean }) => ({
    position: "absolute",
    top: props.isMobile ? 0 : "30px",
    left: "30px",
    width: "50%",
    textAlign: "left",
    backgroundColor: "transparent",
  })
);

const MainWrapper = stackStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: "flex",
    maxWidth: "1280px",
    width: props.isMobile ? "95%" : props.isTablet ? "90%" : "100%",
    justifyContent: "center",
    padding: props.isMobile ? "100px 10px" : "50px 10px",
    marginLeft: "auto",
    marginRight: "auto",
    overflowX: "hidden",
    textAlign: "center",
  })
);

export const KeysReplacementPayment: React.FC<PageProps> = (
  props: PageProps
) => {
  const [isMobile, isTablet] = useViewPortSize();
  const [showPaypal, setShowPaypal] = useState(false);

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll id="main">
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            pages={[
              { title: "Home", href: "/" },
              { title: 'Customer service', href: '/customer-service' },
              {
                title: "Lost Combination",
                href: "/customer-service/lost-combination-assistance",
              },
              { title: "Payment", href: "/lost-combination-payment" },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          {showPaypal ? (
            <CombinationPaypal
              title={"Combination request payment"}
              subTitle={
                "You requested a combination retrieval for your Stack-On. Our Customer Service team has set up a secure PayPal page in order to purchase your code."
              }
            />
          ) : (
            <PaypalPayment secondStep={() => setShowPaypal(true)} />
          )}
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default KeysReplacementPayment;
