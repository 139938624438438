import React, { useState } from "react";
import PaypalSuccess from "-!svg-react-loader!../../../assets/payment-completed-card.svg";
import PayPalIcon from "-!svg-react-loader!../../../assets/paypal-icon.svg";
import { useOrientation, useViewPortSize } from "../../../hooks";
import { stackStyled, Typography, useStackStyletron } from "../../../theme";
import { PayPal } from "../../../atoms";

const ButtonContainer = stackStyled("div", {
  paddingTop: "30px",
  width: "100%",
});

const MainContainer = stackStyled(
  "div",
  ({ isRotated }: { isRotated: boolean }) => ({
    padding: isRotated ? "50px 0px 50px 0px" : "",
    width: "80%",
  })
);

interface ICombinationPaypal {
  title: string;
  subTitle: string;
}

const CombinationPaypal = (props: ICombinationPaypal) => {
  // HOOKS
  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();

  //STATE
  const [amount, setAmount] = useState([
    { label: "Please Choose an Option", id: "0" },
  ]);
  const [showPaypal, setShowPaypal] = useState(false);
  const currency = "USD";

  // RENDER
  const { isRotated } = useOrientation();

  return (
    <MainContainer isRotated={isRotated}>
      <ButtonContainer>
        <PayPalIcon />
        <Typography
          $fontFamily={"Montserrat"}
          $weight={700}
          $tagType={isMobile ? 'h4Mob' : "h4"}
          $textAlign={isMobile ? "left" : "center"}
          $color={theme.stackOnColors.primaryGreenDark}
          $margin={20}
        >
          {props.title}
        </Typography>

        <Typography
          $fontFamily={"Montserrat"}
          $weight={400}
          $tagType={"body"}
          $textAlign={isMobile ? "left" : "center"}
          $color={theme.stackOnColors.primaryBlack}
          $margin={5}
        >
          {props.subTitle}
        </Typography>

        <PayPal
          amount={30}
          currency={"USD"}
          items={[]}
          successfulImage={PaypalSuccess}
          description={amount[0].label}
          successfulText={
            "We have sent your key(s) replacement confirmation to your mail. Be sure emails from cannonsecurityproducts.com and cannonsafe.com are on your safe sender list."
          }
          successfulTitle={"Your payment was completed successfully"}
          onPaymentApproved={(value) => {
            // handleSendData(value);
            // setPaymentDone(true);
          }}
        />
      </ButtonContainer>
    </MainContainer>
  );
};

export default CombinationPaypal;
